<template>
  <div class="row">
    <div class="col-12 text-right mb-3">
      <el-button type="info" @click="exportData" :loading="exporting" icon="el-icon-download">导出报表</el-button>
    </div>
    <div class="col-12">
      <el-table refs="counterTable" row-key="id" v-loading="loading"
        header-row-class-name="thead-light" header-cell-class-name="text-center" style="width: 100%"
        :data="tableData" :summary-method="sum" show-summary stripe>
        <el-table-column prop="company_name" label="企业名称" min-width="200"></el-table-column>
        <el-table-column prop="org_type" label="企业性质" min-width="160"></el-table-column>
        <el-table-column :label="dataTypeName + '产品数据（万元）'">
            <el-table-column v-for="p in provinces" :key="p.name" :label="p.name" class-name="bg-amount">
                <template slot-scope="{row}">
                    {{ getAmount(row.provinces, p.name) }}
                </template>
            </el-table-column>
        </el-table-column>
        <el-table-column prop="sum" label="合计" width="120">
          <template slot-scope="{row}">
              {{ row.sum.toFixed(2) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    filters: {
      type: Object,
      description: "筛选条件",
      default: null
    }
  },
  data() {
    return {
      loading: false,
      exporting: false,
      tableData: [],
      provinces: []
    }
  },
  computed: {
    dataTypeName () {
      switch (this.filters.data_type) {
        case '0': return '采购';
        case '1': return '销售';
        default: return ''
      }
    }
  },
  methods: {
    getDateRange () {
      let name = ''
      name += this.filters.start || (this.filters.year + '-01-01')
      name += '~'
      name += this.filters.year === new Date().getFullYear() ? this.toDateString(new Date()) : this.filters.year + '-12-30'
      return name
    },
    fetchData(isExport) {
      if(!isExport) this.loading = true;
      else this.exporting = true;
      this.axios.get("datainputs/report", { params: { ...this.filters, type: isExport?'xlsx':'' }, responseType: isExport?'blob':'application/json' })
      .then((res) => {
        if(!isExport) {
            if (res.data.errcode == 0) {
                this.tableData = res.data.data;
                this.provinces = res.data.provinces
                this.sums = res.data.sum;
            }
            else {
                this.$notifyErr('未能成功加载数据', res.data.errmsg);
            }
            this.loading = false;
        }
        else {
          var fileURL = window.URL.createObjectURL(new Blob([res.data]));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', `${this.dataTypeName}报表.${this.getDateRange()}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.exporting = false;
        }
      })
      .catch((e) => {
        this.$notifyErr('未能成功加载数据', e.message)
        this.loading = false
        this.exporting = false
      })
    },
    getAmount (provinces, name) {
        const p = provinces.find(x => x.name === name)
        return p ? p.amount.toFixed(2) : '-'
    },
    sum(params) {
        const { columns } = params
        const sums = []
        const $this = this
        let total = 0
        columns.forEach((column, index) => {
          if($this.provinces.length>0) {
            if (index > 1 && index < $this.provinces.length + 2) {
              sums[index] = $this.provinces[index-2].amount.toFixed(2)
              total += $this.provinces[index-2].amount
            }
            else if (index === $this.provinces.length + 2) {
              sums[index] = total.toFixed(2)
            }
            else {
                sums[index] = ''
            }
          }
          else {
            sums[index] = ''
          }
        })
        return sums
    },
    exportData() {
      this.fetchData(true)
    }
  },
  mounted() {
    this.fetchData()
  }
};
</script>
<style>
.no-border-card .card-footer { border-top: 0; }
.filters>div { margin-bottom:15px; }
.filters .search.el-input { width: 230px; }
.filters .el-select { width: 160px }
.cell.action-col-header {
    display: block !important;
    text-align: center;
    padding-top: 9px !important;
}
.bg-amount {
    background-color: rgb(251, 252, 235) !important;
}
.el-table .cell { padding: 0; }
.el-table__footer .cell { font-weight: bold; }
div.el-table tbody td {
    padding: 0.5rem;
}
.filters .fw, .fw.el-input, .fw.el-select, .el-button.fw {
  width: 100% !important;
}
</style>
